import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AZTPromotion from "../views/aztPromotion.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "AZTPromotion",
    component: AZTPromotion,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        el: to.hash,
        top: 64,
        behavior: "smooth",
      };
    } else {
      return { top: 0 };
    }
  },
});

export default router;
