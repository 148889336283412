<script setup lang="ts"></script>

<template>
  <div id="footer" style="width: 100vw; background-color: rgb(0, 32, 73)">
    <v-container class="py-12 text-white">
      <v-card class="elevation-0" style="background-color: transparent">
        <v-card-text class="text-white py-0">
          주소 : 서울 마포구 마포대로 122 15층 주식회사 하이
        </v-card-text>
        <v-card-text class="text-white py-0">
          사업자등록번호 : 603-88-00597
        </v-card-text>
        <v-card-text class="text-white pb-0">
          &#169; HAII corp.ltd (주)하이 | All Rights Reserved by haii.io
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
