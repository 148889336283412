import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import Toast, { PluginOptions } from "vue-toastification";
import VueGtag from "vue-gtag-next";
// import "./plugins/firebase";

import "vue-toastification/dist/index.css";

loadFonts();

const options: PluginOptions = {
  // You can set your default options here
};

const app = createApp(App);

app.use(router);
app.use(vuetify);
app.use(Toast, options);
app.use(VueGtag, {
  appName: "azltalk-app-web-promotion",
  useDebugger: true,
  property: {
    id: "G-RXB3NL4E60",
  },
});
app.mount("#app");
